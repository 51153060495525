.react-dataTable {
    .react-dataTable {
        min-height: 60vh;
    }
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
    height: auto !important;
}

.select__multi-value {
    margin: 2px !important;
}

.select__multi-value__label {

    p,
    small {
        color: $white;
        font-size: 10px;
        margin: 0 !important;
        padding: 0 !important;
    }
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    padding: 0.25rem !important;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell {
    padding: 0.25rem !important;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
    padding: 0.25rem 0.25rem 0.25rem 0.75rem !important;
}

.auto-hw {
    height: auto !important;
    width: auto !important;
}

.bg-light-dark {
    background-color: rgba($dark, 0.12);
    color: $dark;
}

.line-break {
    white-space: pre-line;
}

.select__single-value {
    .hide-single {
        display: none;
    }
}

.sidebar-xxl {
    width: 80vw !important;
}

.badge {
    white-space: break-spaces;
}


#dashboard-ecommerce {
    .accordion-body {
        padding: 0px;
        margin: 0px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-crosshair {
    cursor: crosshair;
}

.cursor-eresize {
    cursor: e-resize;
}

.cursor-move {
    cursor: move;
}

.dashboard-h-13 {
    min-height: 13rem !important;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 8px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #7367f0;
}